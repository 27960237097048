import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'

const ModalGalleryInfo = ({ onClose, isOpen, durationTime, renderTime }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							src={'/assets/images/graphics/modal-clock.png'}
							srcSet="/assets/images/graphics/modal-clock@2x.png 2x, /assets/images/graphics/modal-clock.png 1x"
							alt="Video Length Clock"
						/>
						<Heading as="h3" size="lg" variant="hero">
							Video length: <strong>{durationTime}</strong>
						</Heading>
						<Text>Your video will take an estimated {renderTime} to finalize.</Text>
						<Text>
							Select the Quality Check at checkout for a video expert from VidDay to review and edit your
							media for you.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							variant="ghost"
							as="a"
							href="https://help.vidday.com/05-finalizing/whats-the-quality-check/"
							target="_blank"
							onClick={onClose}>
							Learn More
						</Button>
						<Button onClick={onClose}>Okay, Thanks</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalGalleryInfo
