import React, { useEffect, useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Box, Grid, useMultiStyleConfig, useDisclosure, Flex, Text } from '@chakra-ui/react'
import Element from '../Element'
import AddMedia from '../AddMedia'
import ModalAddMediaItem from '../../../../organisms/ModalAddMediaItem'
import ModalMaxMedia from '../../../../organisms/ModalMaxMedia'
import ModalMaxLength from '../../../../organisms/ModalMaxLength'
import DoubleTapSVG from '../Element/DoubleTapSVG'
import { EmptyMedia } from '../EmptyMedia'
// import Processing from '../../../../atoms/Processing'

const Container = SortableContainer(
	({
		items,
		// isSorting,
		selectedItems,
		// sortingItemKey,
		gridSize = 'medium',
		hashSelection,
		hasCollageBackground,
		videoLengthInSec,
		setMediaToEdit,
		onItemSelect,
		containerWidth,
	}) => {
		const addMediaItem = useDisclosure()
		const maxMedia = useDisclosure()
		const maxLength = useDisclosure()

		// const [processingTimeout, setProcessingTimeout] = useState(false)
		// useEffect(() => {
		// 	var processing = items.filter((i) => !i.signedLocationUrl)
		// 	console.log('processing', processing.length)
		// }, [items])

		const modals = { addMediaItem, maxMedia, maxLength }

		let gridSizeCss = 'repeat(6, 1fr)'

		if (gridSize == 'large') gridSizeCss = containerWidth <= 480 ? 'repeat(1, 1fr)' : 'repeat(5, 1fr)'
		if (gridSize == 'medium') gridSizeCss = containerWidth <= 480 ? 'repeat(2, 1fr)' : 'repeat(7, 1fr)'
		if (gridSize == 'small') gridSizeCss = containerWidth <= 480 ? 'repeat(3, 1fr)' : 'repeat(10, 1fr)'

		const itemsLength = items.length

		const hideTooltip = typeof window != 'undefined' && localStorage ? localStorage?.getItem('viewedmedia') : false

		// this was in SortableElement before but adds ~1-2 ms overhead to each BaseElement render
		const styles = useMultiStyleConfig(`Element`)

		return (
			<Box p="1rem" w="full">
				{!hideTooltip && items?.length > 0 && (
					<Flex
						alignItems="center"
						mb="1rem"
						borderRadius="var(--vidday-radii-lg)"
						bg="var(--vidday-colors-cyan-900)"
						padding=".5rem">
						<DoubleTapSVG />
						<Text color="white" marginLeft=".5rem">
							<strong>Double-tap to edit </strong> media items.
						</Text>
					</Flex>
				)}
				{/* {processingTimeout && items?.length > 0 && (
					<Flex
						alignItems="center"
						mb="1rem"
						borderRadius="var(--vidday-radii-lg)"
						bg="var(--vidday-colors-cyan-900)"
						padding=".5rem">
						<Processing sx={{ margin: '.25rem', maxHeight: '2.5rem', maxWidth: '2.5rem' }} />
						<Text color="white" marginLeft=".5rem">
							<strong>Processing timed out.</strong> Some media items are stuck, reload this page to reset
							them.
						</Text>
					</Flex>
				)} */}
				{items?.length > 0 && (
					<Grid w="full" templateColumns={gridSizeCss} gap={4}>
						{items.map((item, index) => {
							const isSelected = selectedItems.includes(item.id)
							const isFirst = itemsLength > 1 && index == 0
							const isLast = itemsLength > 1 && index + 1 == itemsLength

							return (
								<Element
									key={`item-${item.id}`}
									isSelected={isSelected}
									// selectedCount={selectedCount}
									isFirst={isFirst}
									styles={styles}
									isLast={isLast}
									index={index}
									data={item}
									gridSize={gridSize}
									disabled={!item.id}
									hashSelection={hashSelection}
									onItemSelect={onItemSelect}
									// isSorting={isSorting}
									selectedItemsLength={selectedItems.length}
									setMediaToEdit={setMediaToEdit}
									hasCollageBackground={hasCollageBackground}
								/>
							)
						})}

						<AddMedia modals={modals} itemsLength={items.length} videoLengthInSec={videoLengthInSec} />
					</Grid>
				)}

				{items?.length === 0 && (
					<EmptyMedia modals={modals} itemsLength={items.length} videoLengthInSec={videoLengthInSec} />
				)}

				<ModalAddMediaItem isOpen={addMediaItem.isOpen} onClose={addMediaItem.onClose} />

				<ModalMaxMedia isOpen={maxMedia.isOpen} onClose={maxMedia.onClose} mediaCount={items.length} />

				<ModalMaxLength
					isOpen={maxLength.isOpen}
					onClose={maxLength.onClose}
					videoLengthInSec={videoLengthInSec}
				/>
			</Box>
		)
	}
)

export default Container
