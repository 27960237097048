import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const PreviewSelector = ({ onClick }) => {
	return (
		<NavCard onClick={onClick}>
			<NavCardCover path="/assets/images/graphics/navigation-cards/vidday-event-page-preview" />
			<NavCardContent title={'Preview'} description={'Get a sneak peek'} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
