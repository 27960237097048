import React from 'react'
import { useVideoDurationCalc } from '../../../../../hooks/useVideoDurationCalc'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const FinalizeSelector = ({ media, event, hasSubscription, onClick }) => {
	const { renderTimeInMinutes } = useVideoDurationCalc(media, event.themeTemplateId, true)
	const formatTime = (totalMinutes) => {
		const hours = Math.floor(totalMinutes / 60)
		const minutes = totalMinutes % 60
		if (hours > 0) {
			return `${hours}${hours === 1 ? 'h' : 'h'} ${minutes} ${minutes === 1 ? 'min' : 'mins'}`
		} else {
			return `${minutes} ${minutes === 1 ? 'min' : 'mins'}`
		}
	}
	const path = '/assets/images/graphics/navigation-cards/vidday-event-page-finalize'
	const description = (
		<>
			<strong>~ {formatTime(renderTimeInMinutes)}</strong> to produce
		</>
	)

	return (
		<NavCard onClick={onClick}>
			<NavCardCover path={path} />
			<NavCardContent title={hasSubscription ? 'Finalize' : 'Pay & Finalize'} description={description} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
