import React, { useState, useEffect } from 'react'
import { Flex, Box, Text, Button, HStack, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateEvent } from '../../../../../api/app/events'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { duplicateMedia, updateMedia } from '../../../../../api/app/media'
import useTopIntersect from '../../../../hooks/useTopIntersect'
import { useEventContext } from '../../../../contexts/EventProvider'
import {
	AppsRounded,
	SquareRounded,
	GridViewRounded,
	RemoveCircleRounded,
	CheckCircleRounded,
	SettingsBackupRestoreRounded,
	MusicOffRounded,
	MusicNoteRounded,
	VolumeOffRounded,
	VolumeUpRounded,
	HourGlassBottomRounded,
	HourGlassTopRounded,
	TextureRounded,
	PhotoSizeSelectLargeRounded,
	ContentCopyRounded,
	CancelRounded,
} from '../../../atoms/Icon'
import ModalGalleryDuplicateItems from '../../../organisms/ModalGalleryDuplicateItems'
import { useMediaSortingOrder } from '../../../../hooks/useMediaSortingOrder'
import ModalGalleryRemoveItems from '../../../organisms/ModalGalleryRemoveItems'
import { history } from '../../../../../history'

const GridSizeSelect = ({ onSelect, active }) => {
	return (
		<Tooltip
			label="Select media grid size"
			aria-label="Large Grid"
			hasArrow
			arrowSize={12}
			placement="top"
			fontSize="0.70rem">
			<HStack spacing="0.5rem">
				<SquareRounded
					cursor="pointer"
					color={active == 'large' ? 'link' : 'gray.400'}
					onClick={() => onSelect('large')}
				/>
				<GridViewRounded
					cursor="pointer"
					color={active == 'medium' ? 'link' : 'gray.400'}
					onClick={() => onSelect('medium')}
				/>
				<AppsRounded
					cursor="pointer"
					color={active == 'small' ? 'link' : 'gray.400'}
					onClick={() => onSelect('small')}
				/>
			</HStack>
		</Tooltip>
	)
}

const ButtonAllCollageTheme = (props) => {
	return (
		<Box>
			<Tooltip
				label={'Make selected media ONLY appear in the collage foreground.'}
				aria-label={'Make selected media ONLY appear in the collage foreground.'}
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Make selected media ONLY appear in the collage foreground."
					variant="mediaGalleryButton"
					icon={<TextureRounded />}
					onClick={props.onClickFg}
					borderRightRadius="0"
				/>
			</Tooltip>
			<Tooltip
				label={'Make selected media ONLY appear in the collage backgound.'}
				aria-label={'Make selected media ONLY appear in the collage backgound.'}
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					borderRadius="0"
					aria-label="Make selected media ONLY appear in the collage backgound."
					variant="mediaGalleryButton"
					icon={<PhotoSizeSelectLargeRounded />}
					onClick={props.onClickBg}
				/>
			</Tooltip>
			<Tooltip
				label={'Reset selected media collage theme setting'}
				aria-label={'Reset selected media collage theme setting'}
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Reset selected media collage theme setting"
					variant="mediaGalleryButton"
					icon={<SettingsBackupRestoreRounded />}
					onClick={props.onClickReset}
					borderLeftRadius="0"
				/>
			</Tooltip>
		</Box>
	)
}

const ButtonAllDuration = (props) => {
	return (
		<Box>
			<Tooltip
				label="Decrease image duration time by one second"
				aria-label="Decrease selected media duration time by one second"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Decrease image duration time by one second"
					variant="mediaGalleryButton"
					icon={<HourGlassBottomRounded />}
					onClick={props.onClickRemove}
					borderRightRadius="0"
				/>
			</Tooltip>
			<Tooltip
				label="Increase image duration time by one second"
				aria-label="Increase selected media duration time by one second"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Increase image duration time by one second"
					variant="mediaGalleryButton"
					icon={<HourGlassTopRounded />}
					onClick={props.onClickAdd}
					borderLeftRadius="0"
				/>
			</Tooltip>
		</Box>
	)
}

const ButtonAllDuplicate = (props) => {
	const viewport = useSelector((s) => s.environment.viewport, shallowEqual)
	return (
		<Box>
			<Tooltip
				label="Duplicate selected items"
				aria-label="Duplicate selected items"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				{viewport.width > 420 ? (
					<Button
						variant="mediaGalleryButton"
						onClick={props.onClickDuplicate}
						leftIcon={<ContentCopyRounded />}>
						Duplicate
					</Button>
				) : (
					<Button variant="mediaGalleryButton" onClick={props.onClickDuplicate}>
						<ContentCopyRounded />
					</Button>
				)}
			</Tooltip>
		</Box>
	)
}

const ButtonAllMusic = (props) => {
	return (
		<Box>
			<Tooltip
				label="Mute background music during this segment"
				aria-label="Mute background music during this segment"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Mute background music during this segment"
					variant="mediaGalleryButton"
					icon={<MusicOffRounded color="link" />}
					onClick={props.onClickOn}
					borderRightRadius="0"
				/>
			</Tooltip>
			<Tooltip
				label="Allow background music during this segment"
				aria-label="Allow background music during this segment"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					icon={<MusicNoteRounded color="link" />}
					aria-label="Allow background music during this segment"
					variant="mediaGalleryButton"
					onClick={props.onClickOff}
					borderLeftRadius="0"
				/>
			</Tooltip>
		</Box>
	)
}

const ButtonAllMute = (props) => {
	return (
		<Box>
			<Tooltip
				label="Mute media (video clip) sound"
				aria-label="Mute media (video clip) sound"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Mute media(video clip) sound"
					icon={<VolumeOffRounded color="link" />}
					variant="mediaGalleryButton"
					onClick={props.onClickOn}
					borderRightRadius="0"
				/>
			</Tooltip>
			<Tooltip
				label="Unmute media (video clip) sound"
				aria-label="Unmute media(video clip) sound"
				hasArrow
				arrowSize={12}
				placement="top"
				fontSize="0.70rem">
				<IconButton
					aria-label="Unmute media (video clip) sound"
					icon={<VolumeUpRounded color="link" />}
					variant="mediaGalleryButton"
					onClick={props.onClickOff}
					borderLeftRadius="0"
				/>
			</Tooltip>
		</Box>
	)
}

const allowedSize = ['small', 'medium', 'large']

export const MediaGalleryControls = ({
	eventId,
	eventGridSize,
	media,
	selectedItems,
	setSelectedItems,
	containerRef,
}) => {
	const dispatch = useDispatch()

	const removeItems = useDisclosure()
	const duplicateItems = useDisclosure()

	const { hasAdminPrivilege } = useAuthContext()
	const [gridSize, setGridSize] = useState(eventGridSize || 'medium')

	// need event context to display certain buttons if collage theme is enabled
	const { event } = useEventContext()
	const isCollageTheme = event.themeTemplateId?.includes('collage')

	const handleEmptySelected = () => {
		setSelectedItems([])
	}

	const handleSetGridSize = (sz) => {
		if (allowedSize.includes(sz) && sz !== gridSize) {
			setGridSize(sz)
			dispatch(updateEvent({ id: eventId, gridSize: sz }))
		}
	}
	const handleSelectAll = () => {
		setSelectedItems(media.map((el) => el.id)) //.map((el) => el.id))
	}

	/**
	 * What media type is selected
	 * */
	const [hasImage, setHasImage] = useState(false)
	useEffect(() => {
		let val = selectedItems.filter((m) => {
			var item = media.find((el) => el.id == m)
			return item?.mimeType?.includes('image') || item?.mimeType?.includes('text')
		})
		setHasImage(val.length > 0)
	}, [media, selectedItems])

	// /**
	// * What media type is selected
	// * */
	const [hasVideo, sethasVideo] = useState(false)
	useEffect(() => {
		let val = selectedItems.filter((m) => {
			var item = media.find((el) => el.id == m)
			return item?.mimeType?.includes('video')
		})
		sethasVideo(val.length > 0)
	}, [media, selectedItems])

	/**
	 * Should we show the select button ?
	 * */
	const [showSelectAll, setShowSelectAll] = useState(false)
	useEffect(() => {
		let val = selectedItems.length != media.length
		setShowSelectAll(val)
	}, [media, selectedItems])

	/**
	 * Should we show the unselect button ?
	 * */
	const [showUnselectAll, setShowUnselectAll] = useState(false)
	useEffect(() => {
		let val = selectedItems.length > 0
		setShowUnselectAll(val)
	}, [selectedItems])

	/**
	 * Should we show the delete button ?
	 * */
	const [showDelete, setShowDelete] = useState(false)
	useEffect(() => {
		let val = selectedItems.length > 0
		setShowDelete(val)
	}, [selectedItems])

	const handleBulkAction = (action, bool) => {
		if (action == 'duplicate') {
			if (selectedItems.length > 3) {
				duplicateItems.onOpen()
			} else {
				handleDuplicate()
			}
		} else {
			selectedItems.forEach((m) => {
				let original = media.find((el) => el.id == m)
				let data = { ...original }
				// console.log('OIF WE FOUND THE ITEM', data)
				// let data = { ...m }
				if (action == 'foregroundOnly') {
					data.foregroundEligible = true
					data.backgroundEligible = false
				}
				if (action == 'backgroundOnly') {
					if (!data?.mimeType.includes('video')) {
						data.backgroundEligible = true
						data.foregroundEligible = false
					}
				}
				if (action == 'reset') {
					data.backgroundEligible = bool
					data.foregroundEligible = bool
				}
				if (action == 'durationAdd') {
					if (!data?.mimeType.includes('video') && data.duration < 60) {
						data.duration = data.duration + 1
					}
				}
				if (action == 'durationRemove') {
					if (!data?.mimeType.includes('video') && data.duration > 4) {
						data.duration = data.duration - 1
					}
				}
				if (action == 'muteBackgroundMusic') {
					data.muteBackgroundMusic = bool
				}
				if (action == 'muteAudio') {
					data.muteAudio = bool
				} else {
					!(data?.mimeType.includes('video') && action == 'backgroundOnly') &&
						dispatch(updateMedia(data, false))
				}
			})
		}
	}

	const mediaSortingOrder = useMediaSortingOrder()
	const handleRemoveMedia = () => {
		const newSortingOrder = mediaSortingOrder.filter((mediaId) => !selectedItems.some((id) => id === mediaId))
		// Update new sorting order
		dispatch(
			updateEvent(
				{
					id: eventId,
					mediaSortingOrder: newSortingOrder,
				},
				true
			)
		)
		// Clear selection
		setSelectedItems([])
	}

	const handleDuplicate = () => {
		// Dispach diplications for each selection
		selectedItems.forEach((id, i) => {
			dispatch(duplicateMedia({ id }))
		})
		// Scroll to bottom
		history.push(`/event/${event.uuid}#icon_add_media`)
		// Close Modal
		duplicateItems.onClose()
		// Clear selection
		setSelectedItems([])
	}

	const [intersected, rect] = useTopIntersect(containerRef, -120, 240)
	const viewport = useSelector((s) => s.environment.viewport, shallowEqual)

	return (
		<Flex minW="full" direction="column">
			<ModalGalleryRemoveItems
				isOpen={removeItems.isOpen}
				onClose={removeItems.onClose}
				fileCount={selectedItems.length}
				onRemove={handleRemoveMedia}
			/>

			<ModalGalleryDuplicateItems
				isOpen={duplicateItems.isOpen}
				fileCount={selectedItems.length}
				onClose={duplicateItems.onClose}
				onDuplicate={handleDuplicate}
			/>

			<Box
				position={intersected ? 'fixed' : 'relative'}
				h="60px"
				w={rect?.width ? rect.width : 'full'}
				alignItems="center"
				px="1rem"
				py="0.75rem"
				zIndex="2"
				top={intersected && hasAdminPrivilege ? '48px' : '0'}
				borderTop="1px"
				borderBottom="1px"
				borderColor="blackAlpha.300"
				backgroundColor="rgba(255, 255, 255, 0.85)"
				backdropFilter="saturate(180%) blur(5px)">
				<Box display="flex" justifyContent="space-between">
					<GridSizeSelect onSelect={handleSetGridSize} active={gridSize} />

					<Box>
						<HStack>
							{/**
							 * Do not show the delete button unless we have a selection
							 */}

							{showDelete && (
								<Flex alignContent={'left'}>
									<Tooltip
										colorScheme="red"
										label="Delete one or several file"
										fontSize="xs"
										openDelay={500}>
										<>
											{viewport.width >= 1280 ? (
												<Button
													variant="mediaGalleryButton"
													onClick={removeItems.onOpen}
													leftIcon={<CancelRounded />}>
													Remove
												</Button>
											) : (
												<Button variant="mediaGalleryButton" onClick={removeItems.onOpen}>
													<CancelRounded />
												</Button>
											)}
										</>
									</Tooltip>
								</Flex>
							)}
							{selectedItems.length > 0 && (
								<Text fontSize="0.875rem" px="0.5rem" color="link" display={['none', 'block']}>
									<strong>{selectedItems.length}</strong> selected
								</Text>
							)}

							{viewport.width >= 1280 && selectedItems.length > 0 && (
								<>
									{isCollageTheme && (
										<ButtonAllCollageTheme
											onClickFg={() => handleBulkAction('foregroundOnly')}
											onClickBg={() => handleBulkAction('backgroundOnly')}
											onClickReset={() => handleBulkAction('reset', true)}
										/>
									)}
									{hasVideo && (
										<ButtonAllMute
											onClickOn={() => handleBulkAction('muteAudio', true)}
											onClickOff={() => handleBulkAction('muteAudio', false)}
										/>
									)}
									<ButtonAllMusic
										onClickOn={() => handleBulkAction('muteBackgroundMusic', true)}
										onClickOff={() => handleBulkAction('muteBackgroundMusic', false)}
									/>
									{hasImage && (
										<ButtonAllDuration
											onClickAdd={() => handleBulkAction('durationAdd')}
											onClickRemove={() => handleBulkAction('durationRemove')}
										/>
									)}
									<ButtonAllDuplicate onClickDuplicate={() => handleBulkAction('duplicate')} />
								</>
							)}

							{showUnselectAll && (
								<>
									{viewport.width >= 1280 ? (
										<Button
											variant="mediaGalleryButton"
											onClick={handleEmptySelected}
											leftIcon={<RemoveCircleRounded />}>
											Unselect
										</Button>
									) : (
										<Button variant="mediaGalleryButton" onClick={handleEmptySelected}>
											<RemoveCircleRounded />
										</Button>
									)}
								</>
							)}

							{/**
							 * Select All button, disable if all are selected
							 */}
							<Button
								variant="mediaGalleryButton"
								disabled={!showSelectAll}
								onClick={handleSelectAll}
								leftIcon={<CheckCircleRounded />}>
								Select All
							</Button>
						</HStack>
					</Box>
				</Box>
			</Box>

			{viewport.width < 1280 && (
				<Box
					display="flex"
					justifyContent={['space-around', 'flex-end']}
					position={intersected ? 'fixed' : 'relative'}
					w={rect?.width ? rect.width : 'full'}
					minH="57px"
					alignItems="center"
					px="1rem"
					py="0.75rem"
					zIndex="2"
					top={intersected ? (hasAdminPrivilege ? '108px' : '60px') : '0'}
					borderBottom="1px"
					borderColor="blackAlpha.300"
					backgroundColor="rgba(255, 255, 255, 0.85)"
					backdropFilter="saturate(180%) blur(5px)">
					{showUnselectAll && (
						<HStack>
							{isCollageTheme && (
								<ButtonAllCollageTheme
									onClickFg={() => handleBulkAction('foregroundOnly')}
									onClickBg={() => handleBulkAction('backgroundOnly')}
									onClickReset={() => handleBulkAction('reset', true)}
								/>
							)}
							{hasVideo && (
								<ButtonAllMute
									onClickOn={() => handleBulkAction('muteAudio', true)}
									onClickOff={() => handleBulkAction('muteAudio', false)}
								/>
							)}
							<ButtonAllMusic
								onClickOn={() => handleBulkAction('muteBackgroundMusic', true)}
								onClickOff={() => handleBulkAction('muteBackgroundMusic', false)}
							/>
							{hasImage && (
								<ButtonAllDuration
									onClickAdd={() => handleBulkAction('durationAdd')}
									onClickRemove={() => handleBulkAction('durationRemove')}
								/>
							)}
							<ButtonAllDuplicate onClickDuplicate={() => handleBulkAction('duplicate')} />
						</HStack>
					)}
				</Box>
			)}
		</Flex>
	)
}
