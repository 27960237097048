import React from 'react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const CustomSelector = ({ onClick }) => {
	return (
		<NavCard onClick={onClick}>
			<NavCardCover path="/assets/images/graphics/navigation-cards/vidday-event-page-customize" />
			<NavCardContent title={'Customize'} description={'Themes, music, and more'} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
