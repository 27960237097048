import React, { memo } from 'react'
import { maxMediaLength } from '../../../../../client/config.client'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalMaxLength = memo(({ onClose, isOpen, videoLengthInSec }) => {
	const maxLengthInMin = maxMediaLength / 60
	const currentLength = Math.round(videoLengthInSec / 60)

	const title = `This upload will exceed the event's time limits of ${maxLengthInMin} minutes.`
	const message = `Please remove other media before adding more. <br/> You're current video length is ${currentLength} minutes.`
	const acceptLabel = 'Okay'

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Warning" />
						<Heading size="lg" variant="hero">
							{title}
						</Heading>
						{message}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							onClick={() => {
								onClose()
							}}>
							{acceptLabel}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalMaxLength
