import { useEffect, useState } from 'react'

const useTopIntersect = (ref, topOffset, bottomOffset) => {
	// Sticky State
	const [intersected, setIntersect] = useState(false)
	const [element, setElement] = useState(null)

	const handleIntersect = () => {
		let rect = ref?.current?.getBoundingClientRect() || {}
		setElement(rect)

		let topPos = rect.top + window.scrollY + topOffset // 82 from top line
		let bottomPos = rect.bottom + window.scrollY - bottomOffset // 450 hight from container bottom

		if (window.pageYOffset > topPos && window.pageYOffset < bottomPos) {
			setIntersect(true)
		} else {
			setIntersect(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleIntersect)
		return () => {
			window.removeEventListener('scroll', handleIntersect)
		}
	}, [])

	return [intersected, element]
}
export default useTopIntersect
