import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { FormControl, Input, FormHelperText, FormErrorMessage } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schemaDelete = Yup.object().shape({
	deleteButton: Yup.string()
		.matches(/delete/, { message: 'Please type delete to remove media.' })
		.required(),
})

const DeleteFiles = (props) => {
	const { onClose, onDelete, fileCount } = props

	const onSubmit = (data) => {
		onDelete()
		onClose()
	}

	return (
		<>
			<ModalBody>
				<VStack textAlign="center" spacing="1rem">
					<Image
						maxW="100px"
						src="/assets/images/graphics/modal-delete.png"
						srcSet="/assets/images/graphics/modal-delete@2x.png 2x, /assets/images/graphics/modal-delete.png 1x"
						alt="Video Length Clock"
					/>
					<Heading as="h3" size="lg" variant="hero">
						Delete Media?
					</Heading>

					<Text>
						You are about to{' '}
						<strong>
							delete {fileCount} item{fileCount > 1 ? 's' : ''}
						</strong>{' '}
						and you will not be able to recover {fileCount > 1 ? 'them' : 'it'}.
					</Text>
				</VStack>
			</ModalBody>
			<ModalFooter>
				<HStack w="full" spacing="1rem" justifyContent="center">
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
					<Button colorScheme="red" onClick={onSubmit}>
						Yes, Delete
					</Button>
				</HStack>
			</ModalFooter>
		</>
	)
}

const DeleteAllFiles = (props) => {
	const { onClose, isOpen, fileCount, onDelete } = props

	const {
		watch,
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schemaDelete),
	})

	const onSubmit = (data) => {
		onDelete()
		onClose()
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							maxW="100px"
							src="/assets/images/graphics/modal-delete.png"
							srcSet="/assets/images/graphics/modal-delete@2x.png 2x, /assets/images/graphics/modal-delete.png 1x"
							alt="Delete Media"
						/>
						<Heading as="h3" size="lg" variant="hero">
							Delete Media?
						</Heading>

						<Text>
							You are about to <strong>delete {fileCount} items</strong> and you will not be able to
							recover it.
						</Text>

						<FormControl isInvalid={errors.deleteButton}>
							{/* <FormLabel htmlFor='email'>Email</FormLabel> */}
							<Input
								id="deleteButton"
								type="text"
								name="deleteButton"
								ref={register}
								// value={input}
								// onChange={handleInputChange}
								// {...register('deleteButton')}
							/>
							{Object.keys(errors).length == 0 ? (
								<FormHelperText>Tap the word "delete" to confirm</FormHelperText>
							) : (
								<FormErrorMessage>
									{errors.deleteButton && errors.deleteButton.message}
								</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							type="submit"
							// onClick={handleSubmit}
						>
							Yes, Delete
						</Button>
					</HStack>
				</ModalFooter>
			</form>
		</>
	)
}

const ModalGalleryDeleteItems = (props) => {
	const { onClose, isOpen, fileCount, onDelete, deleteAll } = props

	const renderJobsDetails = { previewCountInProgress: 0 }
	const media = []
	const deleteLimit = 0

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				{fileCount > 1 && deleteAll && <DeleteAllFiles {...props} />}

				{fileCount >= 1 && !deleteAll && <DeleteFiles {...props} />}
			</ModalContent>
		</Modal>
	)
}

export default ModalGalleryDeleteItems
