import { useSelector, shallowEqual } from 'react-redux'

/**
 * Retrieve media of the current event that is NOT in the sorting order
 * @param {*} event
 * @returns
 */
export const useMediaUnused = () => {
	/** Retrieve all medias from the store */
	const media = useSelector((s) => s.event.media, shallowEqual)
	const sortedMedia = useSelector((s) => s.event.event.mediaSortingOrder, shallowEqual)

	const unsortedMedia =
		media.length && media.filter((item) => !sortedMedia.includes(item.id) && item.hasOwnProperty('location'))

	return unsortedMedia

	// /** Retrieve all medias from the store */
	// const [media, setMedia] = useState()
	// const [sortedMedia, setSortedMedia] = useState()
	// const reduxMedia = useSelector((s) => s.event.media, shallowEqual)
	// const mediaSortingOrder = useSelector((s) => s.event.event.mediaSortingOrder, shallowEqual)
	// useEffect(()=>{
	// 	if(JSON.stringify(sortedMedia) !== JSON.stringify(mediaSortingOrder)){
	// 		const m = reduxMedia.length && reduxMedia.filter((item) => mediaSortingOrder.length && !mediaSortingOrder.includes(item.id))
	// 		setMedia(m)
	// 		setSortedMedia(mediaSortingOrder)
	// 	}
	// },[mediaSortingOrder])

	// return media
}
