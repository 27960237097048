/** CONVERT TO CHAKRA */

import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Box,
} from '@chakra-ui/react'
import Processing from '../../atoms/Processing'

export const MediaPendingJobModal = ({ isOpen, onClose, mediaStatus }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center">
						<Box w="120px">
							<Processing />
						</Box>

						<Heading size="lg" variant="hero">
							{mediaStatus === 'processing' && 'Media still processing…'}
							{mediaStatus === 'error' && 'Error processing media.'}
							{mediaStatus === 'upscaling' && 'Media still Upscaling.'}
						</Heading>
						<Text>
							{mediaStatus === 'processing' &&
								"Your video can't be finalized until all your media are done processing. Please try again in a couple of minutes."}
							{mediaStatus === 'error' &&
								'There was an error processing a piece of media, please remove it and try again.'}
							{mediaStatus === 'upscaling' &&
								'A piece of media was upscaled but has not been saved. Please review it before proceeding.'}
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" mt="-1rem" justifyContent="center">
						<Button
							variant="outline"
							onClick={() => {
								if (window && window?.LC_API?.open_chat_window) {
									window.LC_API.open_chat_window()
								}
								onClose()
							}}>
							Chat with Support
						</Button>
						<Button onClick={onClose}>Okay, Thanks</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
