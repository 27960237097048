import { useEffect } from 'react'
import PropTypes from 'prop-types'

const useScrollToHash = ({ location, offset }) => {
	useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById(location.hash.replace('#', ''))
			if (element) {
				const rect = element.getBoundingClientRect() // get rects(width, height, top, etc)
				if (typeof window !== 'undefined')
					window.scrollTo({
						top: rect.top + window.pageYOffset - (offset || rect.height / 2),
						behavior: 'smooth', // smooth scroll
					})
			}
		}, 150)
	}, [location.hash])

	return null
}

useScrollToHash.propTypes = {
	location: PropTypes.shape({
		hash: PropTypes.string,
	}).isRequired,
}

export default useScrollToHash
