import React, { useState, memo } from 'react'
import { Flex, Box, Text, Heading, Button, VStack, HStack, useDisclosure } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import {
	Thumbnail,
	BottomSection,
	CollageBackground,
	CollageForeground,
	BackgroundMusic,
	Muted,
} from '../SortableContainer/Element/atoms'
import { deleteMedia, unArchiveMedia } from '../../../../../api/app/media'
import { computeMimeType } from '../../../../contexts/MediaItemProvider'
import {
	ArrowForwardRounded,
	ArrowDownwardRounded,
	KeyboardArrowUpRounded,
	KeyboardArrowDownRounded,
} from '../../../atoms/Icon'
import ModalGalleryDeleteItems from '../../../organisms/ModalGalleryDeleteItems'
import { updateEvent } from '../../../../../api/app/events'
import { NavCardActions, NavCardButton, NavCardContent, NavWrapper } from '../../../atoms/NavigationCard'

const MediaRemove = memo(({ hasCollageBackground, items, eventId, mediaSortingOrder }) => {
	const dispatch = useDispatch()
	const tray = useDisclosure()
	const deleteModal = useDisclosure()
	const [idToDelete, setIdToDelete] = useState(null)

	const handleAddMediaToGallery = (id) => {
		let newSortingOrder = [...mediaSortingOrder]
		newSortingOrder.push(id)
		dispatch(
			updateEvent(
				{
					id: eventId,
					mediaSortingOrder: newSortingOrder,
				},
				true
			)
		)
	}

	const handleArchiveMedia = () => {
		dispatch(deleteMedia(idToDelete, false, true))
		deleteModal.onClose()
	}

	return (
		<Box w="full" p="1rem" borderTop="1px" borderColor="blackAlpha.300">
			<ModalGalleryDeleteItems
				isOpen={deleteModal.isOpen}
				onClose={deleteModal.onClose}
				onDelete={handleArchiveMedia}
				fileCount="1"
			/>
			<NavWrapper onClick={tray.onToggle}>
				<NavCardContent
					title={`Unused (${items.length})`}
					description="This media will not be part of the final video."></NavCardContent>
				<NavCardActions p="0">
					<NavCardButton>
						{tray.isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
					</NavCardButton>
				</NavCardActions>
			</NavWrapper>
			{tray.isOpen && (
				<VStack mt="1rem">
					{items.length == 0 ? (
						<Heading as="h4" size="md" p="3rem 0">
							There is no unused media.
						</Heading>
					) : (
						<>
							{items.map((el, i) => {
								const type = computeMimeType(el.mimeType)
								const isVideo = type == 'video'

								/**
								 * Tags Conditions
								 */
								const displayCollageBackground =
									el.backgroundEligible &&
									!el.foregroundEligible &&
									el.signedThumbnailUrl &&
									hasCollageBackground

								const displayCollageForeground =
									el.foregroundEligible &&
									!el.backgroundEligible &&
									el.signedThumbnailUrl &&
									hasCollageBackground

								const displayBackgroundMusic = el.muteBackgroundMusic
								const displayMute = el.muteAudio && isVideo

								return (
									<Flex
										border="1px"
										borderColor="blackAlpha.300"
										w="full"
										minH="100px"
										borderRadius="md"
										overflow="hidden"
										key={i}
										justify="space-between"
										pr="1rem">
										<Box position="relative" h="100px" w="100px" borderRadius="md">
											<Thumbnail item={el} gridSize="small" />
											<BottomSection item={el} hasCollageBackground={hasCollageBackground} />
											<Box position="absolute" top="0" left="0" right="0" h="75px">
												<VStack p="0.5rem" align="start" spacing="0.25rem">
													{displayCollageBackground && <CollageBackground />}
													{displayCollageForeground && <CollageForeground />}
													{displayBackgroundMusic && <BackgroundMusic />}
													{displayMute && <Muted />}
												</VStack>
											</Box>
										</Box>
										<HStack spacing="0.5rem">
											<Button onClick={() => handleAddMediaToGallery(el.id)} size="sm">
												Move to Gallery
											</Button>
											<Button
												onClick={() => {
													setIdToDelete(el.id)
													deleteModal.onOpen()
												}}
												size="sm"
												colorScheme="red">
												Delete
											</Button>
										</HStack>
									</Flex>
								)
							})}
						</>
					)}
				</VStack>
			)}
		</Box>
	)
})

export default MediaRemove
