import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Container from '../Container'
import { isBrowser } from 'react-device-detect'

function areEqual(prevProps, nextProps) {
	if (JSON.stringify(prevProps.selectedItems) !== JSON.stringify(nextProps.selectedItems)) {
		/** Only re-render if selectedItems are different */
		return false
	} else if (JSON.stringify(prevProps.localSortingOrder) !== JSON.stringify(nextProps.localSortingOrder)) {
		/** Only re-render if localSortingOrder are different */
		return false
	} else if (prevProps.gridSize !== nextProps.gridSize) {
		/** Only re-render if there is change in gridSize */
		return false
	} else if (prevProps.isSorting !== nextProps.isSorting) {
		/** Only re-render if there is change isSorting meaning we are dragging items around */
		return false
	} else if (
		JSON.stringify(prevProps.items) !== JSON.stringify(nextProps.items) &&
		JSON.stringify(prevProps.media) !== JSON.stringify(nextProps.media)
	) {
		return false
	} else {
		return true
	}
}

const Grid = memo(
	({
		items,
		selectedItems,
		// setSortingOrder,
		// isSorting,
		onBeforeSortStart,
		onSortStart,
		onSortEnd,
		gridSize,
		hasCollageBackground,
		/*themeTemplateId,*/
		hashSelection,
		processingMediaUuids,
		/*event,*/
		videoLengthInSec,
		containerWidth,

		setMediaToEdit,
		onItemSelect,
	}) => {
		/**
		 * Configure Delay before press and offset distance
		 *  */
		var delay = 150,
			distance = 0

		if (isBrowser) {
			delay = 0
			distance = 10
		}

		return (
			<Container
				/** Data */
				/*event={event}*/
				items={items}
				selectedItems={selectedItems}
				// setSortingOrder={setSortingOrder}
				containerWidth={containerWidth}
				/** Props */
				// isSorting={isSorting}
				hashSelection={hashSelection}
				processingMediaUuids={processingMediaUuids}
				gridSize={gridSize}
				hasCollageBackground={hasCollageBackground}
				/*themeTemplateId={themeTemplateId}*/
				videoLengthInSec={videoLengthInSec}
				/** React Sortable HOC Props */
				disableAutoscroll={false}
				useWindowAsScrollContainer={true}
				transitionDuration={300}
				axis="xy"
				helperClass="dragging"
				hideSortableGhost={selectedItems.length ? false : true} // Some reason this needs to leave ghost on when group sorting
				/** Delay and Offset Distance */
				pressDelay={delay}
				distance={distance} // removing distance fixes error https://github.com/clauderic/react-sortable-hoc/issues/264  https://vidday-media-inc.sentry.io/issues/1894349427/feedback/?project=5242581&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d
				/** Handlers */
				setMediaToEdit={setMediaToEdit}
				onItemSelect={onItemSelect}
				updateBeforeSortStart={onBeforeSortStart}
				onSortStart={onSortStart}
				onSortEnd={onSortEnd}
			/>
		)
	},
	areEqual
)

Grid.propTypes = {
	items: PropTypes.array.isRequired,
	selectedItems: PropTypes.array,
	// isSorting: PropTypes.bool,
	processingMediaUuids: PropTypes.array,
	// themeTemplateId: PropTypes.string.isRequired,
	gridSize: PropTypes.string.isRequired,
	hashSelection: PropTypes.string,
	os: PropTypes.string,
	videoLengthInSec: PropTypes.number,
	setMediaToEdit: PropTypes.func.isRequired,
	onItemSelect: PropTypes.func.isRequired,
	updateBeforeSortStart: PropTypes.func,
	onSortEnd: PropTypes.func.isRequired,
	onSortStart: PropTypes.func,
}

Grid.defaultProps = {
	items: [],
	gridSize: 'medium',
}

export default Grid
