import { useState, useEffect } from 'react'

const useCollageBackground = (event) => {
	/** Manage Mute Audio State */
	const [hasCollageBackground, setHasCollageBackground] = useState(
		event && event.themeTemplateId?.includes('collage') ? true : false
	)

	useEffect(() => {
		const predicate = event?.themeTemplateId?.includes('collage') || false
		if (predicate && hasCollageBackground != predicate) {
			setHasCollageBackground(true)
		} else if (predicate) {
			setHasCollageBackground(true)
		} else setHasCollageBackground(false)
	}, [event])

	return hasCollageBackground
}
export default useCollageBackground
