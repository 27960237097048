import { useState, useEffect } from 'react'

/**
 * Method that allow sorting media in a given sorting order
 * @param {array} items
 * @param {array} sortingOrder
 */
export const sortMedia = (items, sortingOrder = null) => {
	// Host reference to media that were contained in the 'mediaSortingOrder' from the event
	var sortedMedia = items.filter((n) => {
		return n.id !== null && n.id !== undefined
	})
	// Make sure we have media
	if (items && items.length > 0 && sortingOrder) {
		// Add medias to their respective array and appends the unfound items at the end
		// uniq = [...new Set(array)];
		sortedMedia.sort((item1, item2) => {
			let index1 = sortingOrder.indexOf(item1.id)
			let index2 = sortingOrder.indexOf(item2.id)
			return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity)
		})
		return sortedMedia
	}
}

/**
 * Hook that returns a collection of sorted media given a collection of media, and a sorting order.
 * @param {*} media
 * @param {*} sortedOrder
 */
const useSortedMedia = (media, sortedOrder) => {
	const [sortedMedia, setSortedMedia] = useState(media)

	useEffect(() => {
		const reSorted = sortMedia(media, sortedOrder)
		setSortedMedia(reSorted)
	}, [media, sortedOrder])

	return sortedMedia.filter((n) => {
		return n.id !== null
	})
}

export default useSortedMedia
