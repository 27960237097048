import React from 'react'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import useCashCollectionGroup from '../../../../../hooks/useCashCollectionGroup'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useEventRecipients } from '../../../../../hooks/useRecipients'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const CashCollectionSelector = ({ amount, onClick }) => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const [cashCollectionGroup, handleGroupChange] = useCashCollectionGroup(event)

	const description =
		cashCollectionGroup == 'everyone' ? (
			amount == 0 ? (
				<>Invitees can add funds for {recipients.firstname}</>
			) : (
				<>
					<strong>{formatToDecimalAmount(amount)}</strong> collected
				</>
			)
		) : amount == 0 ? (
			<>Let invitees add funds for {recipients.firstname} </>
		) : (
			// <>Invitees can&apos;t chip in</>
			<>
				<strong>{formatToDecimalAmount(amount)}</strong> collected
			</>
		)

	return (
		<NavCard onClick={onClick}>
			{cashCollectionGroup == 'everyone' ? (
				<NavCardCover path="/assets/images/graphics/navigation-cards/event-page-group-gift-card-on" />
			) : (
				<NavCardCover path="/assets/images/graphics/navigation-cards/event-page-group-gift-card-off" />
			)}

			<NavCardContent title={'Group Gift Card'} description={description} />
			<NavCardActions>
				<NavCardButton />
			</NavCardActions>
		</NavCard>
	)
}
