import React, { memo } from 'react'
import Card from '../../atoms/Card'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalHeader,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import { CameraAltRounded, TextSnippetRounded, GifBoxRounded, InsertPhotoRounded } from '../../atoms/Icon'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useParams } from 'react-router'
import { history } from '../../../../history'

const itemCardStyles = {
	w: 'full',
	p: '1rem',
	boxShadow: 'base',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	flexDirection: 'row',
	_hover: { cursor: 'pointer' },
}

const buttonStyles = {
	size: 'md',
	mr: '1rem',
	_hover: { cursor: 'pointer' },
}

const ItemCard = memo(({ icon, label, onClick }) => {
	return (
		<Card onClick={onClick} sx={itemCardStyles}>
			<Button as="div" aria-label={label} variant="iconCta" sx={buttonStyles}>
				{icon}
			</Button>
			<Text color="var(--vidday-colors-link)" fontWeight="600" _hover={{ cursor: 'pointer' }}>
				{label}
			</Text>
		</Card>
	)
})

const ModalAddMediaItem = memo(({ onClose, isOpen }) => {
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />

			<ModalContent>
				<ModalHeader
					borderTopRadius="md"
					overflow="hidden"
					position="absolute"
					top="0"
					left="0"
					right="0"
					minH="100px">
					<Box
						clipPath="ellipse(100% 100% at 50% 0%)"
						backgroundColor="#003399"
						position="absolute"
						left="0"
						top="0"
						right="0"
						minH="100px"
					/>

					<Image
						src="/assets/images/bg/cover-photo-confetti.svg"
						position="absolute"
						top="-75px"
						left="50%"
						transform="translateX(-50%)"
					/>
				</ModalHeader>

				<ModalCloseButton color="white" />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem" pt="100px">
						<Image
							position="absolute"
							top="60px"
							src={'/assets/images/graphics/upload-modal-icon.svg'}
							alt="Video Length Clock"
						/>
						<Heading size="lg" variant="hero">
							Choose Media
						</Heading>
						<ItemCard
							label="Record or Upload"
							onClick={() => history.push(handlers.upload)}
							icon={<CameraAltRounded color="link" />}
						/>
						<ItemCard
							label="Text Card"
							onClick={() => history.push(handlers.upload_card)}
							icon={<TextSnippetRounded color="link" />}
						/>
						{/* <ItemCard
							label="Explore Add-ons"
							onClick={() => history.push(handlers.add_ons)}
							icon={WidgetsRoundedIcon}
						/> */}
						<ItemCard
							label="Animated GIFs"
							onClick={() => history.push(handlers.upload_gif)}
							icon={<GifBoxRounded color="link" />}
						/>
						<ItemCard
							label="Stock Images"
							onClick={() => history.push(handlers.upload_unsplash)}
							icon={<InsertPhotoRounded color="link" />}
						/>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalAddMediaItem
