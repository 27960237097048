import React, { useState, useRef, memo } from 'react'
import { Box } from '@chakra-ui/react'
import { useSelector, shallowEqual } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../../atoms/Card'
import { useVideoDurationCalc } from '../../../../hooks/useVideoDurationCalc'
import { useMedia } from '../../../../hooks/useMedia'
import useScrollToHash from '../../../../hooks/useScrollToHash'
import { MediaGalleryControls } from '../MediaGalleryControl'
import { updateEvent } from '../../../../../api/app/events'
import SortableMedia from '../SortableContainer'
import ArchivedTab from '../ArchivedTab'
import MediaRemove from '../MediaRemove'
import MediaGalleryHeader from '../MediaGalleryHeader'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { useMediaArchived } from '../../../../hooks/useMediaArchived'
import { useMediaUnused } from '../../../../hooks/useMediaUnused'
import { useContainerDimensions } from '../../../../hooks/useContainerDimensions'
import { history } from '../../../../../history'
import useCollageBackground from '../../../../hooks/useCollageBackground'
import { useMediaSortingOrder } from '../../../../hooks/useMediaSortingOrder'
import { useEventContext } from '../../../../contexts/EventProvider'

// const areEqual = (prevProps, nextProps) => {
// 	console.log("are equal ", prevProps, nextProps)
// 	/**
// 	 * Check if events are the same
// 	 */
// 	if(prevProps.event !== nextProps.event){
// 		console.log("are not equal !==", nextProps)
// 		return false
// 	} else {
// 		console.log("are Deep equal ===")
// 		if (
// 			JSON.stringify(prevProps.event) === JSON.stringify(nextProps.event)
// 			// (prevProps.event && nextProps.event) &&
// 			// (prevProps.event.id === nextProps.event.id) &&
// 			// (prevProps.event.updatedAt === nextProps.event.updatedAt)
// 		) {
// 			return true
// 		}
// 	}
// }

// USE USING LOADABLE IMPORTANT
// import loadable from '@loadable/component'
// const SortableMedia = loadable(() => import(/* webpackPrefetch: true */ '../SortableContainer'), { ssr: false }) //from '../SortableContainer' //

const MediaGallery = () => {
	const { isAdmin } = useAuthContext()
	const { event } = useEventContext()
	const media = useMedia()
	const navigate = useNavigate()
	const mediaSortingOrder = useMediaSortingOrder()
	const archivedItems = useMediaArchived()
	const unusedItems = useMediaUnused()
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)
	const { os } = useSelector((s) => s.environment, shallowEqual)
	// const [sortingOrder, setSortingOrder] = useState(mediaSortingOrder)
	const containerRef = useRef(null)

	/** Items that are selected */
	const [selectedItems, setSelectedItems] = useState([])

	/** OLD IMPLEMENTATION */
	const dispatch = useDispatch()
	const location = useLocation()

	const hasCollageBackground = useCollageBackground(event)

	// Hash to media and remove hash
	useScrollToHash({ location })
	let hashSelection = location.hash

	const clearHash = () => {
		if (location?.hash) {
			navigate({ hash: '' })
		}
	}
	const { width } = useContainerDimensions(containerRef)

	const handleEditMedia = React.useCallback(
		(data) => {
			history.push('/event/' + event.uuid + '/media/' + data.id)
		},
		[event]
	)

	const handleOnBeforeSortStart = (item) => {
		clearHash()
		//if the item being dragged is not part of the selected group, clear the selected items group.
		if (!selectedItems.includes(item.node.id)) {
			setSelectedItems([])
		}
	}

	return (
		<Card flexDirection="column">
			{media && media.length > 0 && (
				<>
					<MediaGalleryHeader event={event} />
					<MediaGalleryControls
						media={media}
						eventId={event.id}
						eventGridSize={event.gridSize}
						os={os}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						containerRef={containerRef}
					/>
				</>
			)}

			<Box w="full" ref={containerRef}>
				<SortableMedia
					view="grid"
					// Data
					media={media}
					selectedItems={selectedItems} // Items currently selected
					setSelectedItems={setSelectedItems} // Handler to set selected items
					eventId={event.id}
					gridSize={event.gridSize}
					hasCollageBackground={hasCollageBackground}
					sortOrder={mediaSortingOrder}
					// setSortingOrder={setSortingOrder}
					coverPhotoId={event.coverPhotoId}
					os={os}
					hashSelection={hashSelection}
					videoLengthInSec={durationSec}
					// actions
					handleOnBeforeSortStart={handleOnBeforeSortStart}
					dispatch={dispatch}
					updateEvent={updateEvent}
					containerWidth={width}
					setMediaToEdit={handleEditMedia}
				/>
			</Box>

			{unusedItems?.length > 0 && (
				<MediaRemove
					mediaSortingOrder={mediaSortingOrder}
					eventId={event.id}
					hasCollageBackground={hasCollageBackground}
					items={unusedItems}
				/>
			)}

			{archivedItems?.length > 0 && isAdmin && (
				<ArchivedTab hasCollageBackground={hasCollageBackground} items={archivedItems} />
			)}

			{/* <ParticipationTab eventUuid={event.uuid} /> */}
		</Card>
	)
}

export default MediaGallery
