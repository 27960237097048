import React from 'react'

export const LightBulbSVG = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="34.836" height="37.582" viewBox="0 0 34.836 37.582">
			<g transform="translate(-0.002)">
				<g transform="translate(7.333 8.358)">
					<path
						d="M15.6,21.919H4.641v-.913a5.568,5.568,0,0,0-1.671-3.912,10.043,10.043,0,1,1,14.242.058A5.411,5.411,0,0,0,15.6,21.006Zm0,0"
						transform="translate(-0.076 0)"
						fill="#f7b030"
					/>
					<path
						d="M80.983,416.156h9.13a.913.913,0,1,1,0,1.826h-9.13a.913.913,0,1,1,0-1.826Zm0,0"
						transform="translate(-75.506 -392.41)"
						fill="#47596a"
					/>
					<path
						d="M112.983,480.156h5.478a.913.913,0,1,1,0,1.826h-5.478a.913.913,0,0,1,0-1.826Zm0,0"
						transform="translate(-105.68 -452.759)"
						fill="#47596a"
					/>
					<g transform="translate(3.651 9.138)">
						<path
							d="M64.07,160.156H65.9v1.826H64.07Zm0,0"
							transform="translate(-64.07 -160.156)"
							fill="#fff"
						/>
						<path
							d="M128.07,161.982H129.9v10.955h1.826V161.982h1.826v-1.826H128.07Zm0,0"
							transform="translate(-124.418 -160.156)"
							fill="#fff"
						/>
						<path
							d="M256.07,160.156H257.9v1.826H256.07Zm0,0"
							transform="translate(-245.115 -160.156)"
							fill="#fff"
						/>
					</g>
				</g>
				<g transform="translate(34.837 19.16) rotate(180)">
					<g transform="translate(0 0)">
						<path
							d="M1.742,0h0A1.736,1.736,0,0,0,0,1.742V3.484A1.747,1.747,0,0,0,1.742,5.226h0A1.747,1.747,0,0,0,3.484,3.484V1.742A1.747,1.747,0,0,0,1.742,0Z"
							transform="translate(15.677 13.935)"
							fill="#f7b030"
						/>
						<path
							d="M1.746.5.509,1.733A1.737,1.737,0,0,0,2.966,4.189L4.2,2.952A1.735,1.735,0,0,0,4.2.5,1.767,1.767,0,0,0,1.746.5Z"
							transform="translate(5.204 10.111)"
							fill="#f7b030"
						/>
						<path
							d="M5.226,1.742A1.747,1.747,0,0,0,3.484,0H1.742A1.747,1.747,0,0,0,0,1.742H0A1.747,1.747,0,0,0,1.742,3.484H3.484A1.747,1.747,0,0,0,5.226,1.742Z"
							fill="#f7b030"
						/>
						<path
							d="M3.484,0H1.742A1.747,1.747,0,0,0,0,1.742H0A1.747,1.747,0,0,0,1.742,3.484H3.484A1.747,1.747,0,0,0,5.226,1.742h0A1.747,1.747,0,0,0,3.484,0Z"
							transform="translate(29.611)"
							fill="#f7b030"
						/>
						<path
							d="M2.966.509A1.737,1.737,0,0,0,.509,2.966L1.746,4.2A1.737,1.737,0,0,0,4.2,1.746Z"
							transform="translate(24.922 10.098)"
							fill="#f7b030"
						/>
					</g>
				</g>
			</g>
		</svg>
	)
}
