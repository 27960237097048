import React from 'react'
import { Box, VStack, Text, Grid, Flex } from '@chakra-ui/react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'
import { LightBulbSVG } from './LightBulbSVG'

export const InvitationSelector = ({ onClick }) => {
	// const hideInviteFlag = typeof window != 'undefined' && localStorage ? localStorage?.getItem('inviteflag') : false
	return (
		<Flex justifyItems="center" flexDirection="column">
			{/* {!hideInviteFlag && (
				<Grid
					bgColor="gray.100"
					w="90%"
					alignSelf="center"
					borderRadius="1rem 1rem 0rem 0rem"
					onClick={onClick}>
					<VStack cursor="pointer" m="0.5rem 0rem">
						<Box position="absolute" transform="translateY(-86%)">
							<LightBulbSVG />
						</Box>
						<Text fontSize="14px">Invite people to submit videos and photos.</Text>
					</VStack>
				</Grid>
			)} */}
			<NavCard onClick={onClick}>
				<NavCardCover path="/assets/images/graphics/navigation-cards/vidday-event-page-invitation" />
				<NavCardContent title={'Invitation'} description={'Get others to participate'} />
				<NavCardActions>
					<NavCardButton />
				</NavCardActions>
			</NavCard>
		</Flex>
	)
}
