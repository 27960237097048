import React from 'react'
import { Heading, Image, VStack, Button, Box } from '@chakra-ui/react'
import { maxMediaCount, maxMediaLength } from '../../../../../../../client/config.client'
import { isDesktop } from 'react-device-detect'
import { MdAddCircle } from 'react-icons/md'

export const EmptyMedia = ({ modals: { maxMedia, maxLength, addMediaItem }, itemsLength, videoLengthInSec }) => {
	const addMedia = () => {
		if (itemsLength >= maxMediaCount) {
			maxMedia.onOpen()
		} else if (videoLengthInSec >= maxMediaLength) {
			maxLength.onOpen()
		} else {
			addMediaItem.onToggle()
		}
	}

	const imageData = {
		src: isDesktop
			? '/assets/images/pages/event/media-gallery-empty-desktop.png'
			: '/assets/images/pages/event/media-gallery-empty-mobile.png',
		width: isDesktop ? 428 : 288,
		height: isDesktop ? 125 : 288,
	}

	return (
		<VStack spacing="1rem" my="2rem" onClick={addMedia}>
			<Image alt={'Media Gallery Empty'} {...imageData} />
			<Box textAlign="center">
				<Heading as="h3" fontSize="24px">
					Upload clips, GIFs, pictures, and text cards.
				</Heading>
			</Box>
			<Button variant="solid">
				Add Media &nbsp;&nbsp;
				<MdAddCircle />
			</Button>
		</VStack>
	)
}
