/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import arrayMove from 'array-move'
import Grid from './Grid'
import { sortMedia } from '../../../../hooks/useSortedMedia'

const SortableMedia = ({
	view, // grid or list
	eventId,
	media,
	hashSelection,
	hasCollageBackground,
	dispatch,
	updateEvent,
	setSelectedItems,
	selectedItems,
	containerWidth,
	// Sort order
	sortOrder,
	handleOnBeforeSortStart,
	// setSortingOrder,
	...rest
}) => {
	/** Store sorted items */
	const [items, setItems] = useState([])
	/** Are we dragging items around? */
	const [sorting, setSorting] = useState(false)
	/**Local sorting order */
	const [localSortingOrder, setLocalSortingOrder] = useState([])
	/** Key of the item being sorted */
	const [sortingItemKey, setSortingItemKey] = useState(null)

	/**
	 * Update sorted media upon receiving a new sort order
	 * */
	useEffect(() => {
		if (JSON.stringify(sortOrder) !== JSON.stringify(localSortingOrder)) {
			const newSortedMedia = sortMedia(media, sortOrder)
			setItems(newSortedMedia)
			setLocalSortingOrder(sortOrder)
		}
	}, [sortOrder])

	useEffect(() => {
		if (media && items && media.length == items.length) {
			const newSortedMedia = sortMedia(media, sortOrder)
			setItems(newSortedMedia)
		}
	}, [media])

	/**
	 * On Sort start handler
	 */
	const onSortStart = () => {
		setSorting(true)
	}

	/**
	 * On Sort end handler
	 */
	const onSortEnd = ({ oldIndex, newIndex }) => {
		// done sorting
		setSorting(false)

		let newSortMedia
		// Do we have a group` multi-item sorting?
		if (selectedItems.length > 1) {
			const filteredItems = items.filter((item) => !selectedItems.includes(item.id))

			// IF WE WANTED TO CONSERVE THE ORDER IN WHICH FILES ARE SELECTED, WE NEED TO SORT 'itemsBeingSelected' according to the 'selectedItems'
			const itemsBeingSelected = items.filter((item) => selectedItems.includes(item.id))

			newSortMedia = [
				...filteredItems.slice(0, newIndex),
				...itemsBeingSelected,
				...filteredItems.slice(newIndex, filteredItems.length),
			]
		}
		// Single-item sorting
		else {
			newSortMedia = arrayMove(items, oldIndex, newIndex)
		}

		// Get the ID of the sorted media to store as the sorting order
		const newSortingOrder = newSortMedia.map((m) => m.id)

		// Has anything chnaged? could maybe use useMemo or useCallback here?
		if (JSON.stringify(newSortingOrder) !== JSON.stringify(localSortingOrder)) {
			dispatch(
				updateEvent(
					{
						id: eventId,
						mediaSortingOrder: newSortingOrder,
					},
					true
				)
			)
			// Set the new media objects array
			/**
			 * Update local collection of media
			 */
			setItems(newSortMedia)
			/**
			 * Update local media sortingOrder
			 */
			setLocalSortingOrder(newSortingOrder)
			/**
			 * Update local sortable order
			 */
			// setSortingOrder(newSortingOrder)
		}

		setSelectedItems([]) // Clear selected
		setSortingItemKey(null)
	}

	/**
	 * Handler to select items
	 */
	const handleItemSelect = (itemId) => {
		if (selectedItems.includes(itemId)) {
			const newSelection = selectedItems.filter((val) => val !== itemId)
			setSelectedItems(newSelection)
		} else {
			setSelectedItems([...selectedItems, itemId])
		}
	}

	/**
	 * Function to filter out selected items (used during grouping)
	 */
	const filterOutSelectedItems = (item) => {
		// Do not hide the ghost of the element currently being sorted
		if (sortingItemKey === item.id) {
			return false
		}
		// Hide the other items that are selected
		if (sorting && selectedItems.includes(item.id)) {
			return false
		}
		// Do not hide any other items
		return true
	}

	const gridView = (
		<Grid
			items={items?.filter(filterOutSelectedItems)}
			localSortingOrder={localSortingOrder}
			hasCollageBackground={hasCollageBackground}
			onSortStart={onSortStart}
			onSortEnd={onSortEnd}
			onItemSelect={handleItemSelect}
			isSorting={sorting}
			selectedItems={selectedItems}
			media={media}
			onBeforeSortStart={handleOnBeforeSortStart}
			hashSelection={hashSelection}
			containerWidth={containerWidth}
			{...rest}
		/>
	)

	// const listView = (
	// 	<List
	// 		items={items?.filter(filterOutSelectedItems)}
	// 		onSortStart={onSortStart}
	// 		onSortEnd={onSortEnd}
	// 		onItemSelect={handleItemSelect}
	// 		isSorting={sorting}
	// 		selectedItems={selectedItems}
	// 		onBeforeSortStart={handleOnBeforeSortStart}
	// 		{...rest}
	// 	/>
	// )

	return gridView
	// switch (view) {
	// 	case 'grid':
	// 		return gridView
	// 	case 'list':
	// 		return listView
	// 	default:
	// 		return gridView
	// }
}

SortableMedia.propTypes = {
	view: PropTypes.string,
	eventId: PropTypes.string.isRequired,
	eventUuid: PropTypes.string,
	media: PropTypes.array.isRequired,
	sortOrder: PropTypes.array,
	dispatch: PropTypes.func.isRequired,
	updateEvent: PropTypes.func.isRequired,
}

export default SortableMedia
