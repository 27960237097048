import React from 'react'

function DoubleTapSVG(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="emRAlf8Hn1x1"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
			viewBox="0 0 50 50"
			width={44}
			height={44}
			{...props}>
			<style>
				{
					'\n    @keyframes emRAlf8Hn1x5_ts__ts{0%,11.764706%,35.294118%,58.823529%,to{transform:translate(12.741782px,21.143689px) scale(1,1)}23.529412%,47.058824%{transform:translate(12.741782px,21.143689px) scale(.9,.9)}}@keyframes emRAlf8Hn1x6_ts__ts{0%,17.647059%{transform:translate(23.480067px,15.988149px) scale(0,0)}52.941176%,to{transform:translate(23.480067px,15.988149px) scale(1.880026,1.880026)}}@keyframes emRAlf8Hn1x6_c_o{0%,29.411765%{opacity:.6}52.941176%,to{opacity:0}}@keyframes emRAlf8Hn1x7_ts__ts{0%,41.176471%{transform:translate(23.480067px,15.988149px) scale(0,0)}76.470588%,to{transform:translate(23.480067px,15.988149px) scale(1.880026,1.880026)}}@keyframes emRAlf8Hn1x7_c_o{0%,52.941176%{opacity:.6}76.470588%,to{opacity:0}}#emRAlf8Hn1x5_ts{animation:emRAlf8Hn1x5_ts__ts 1700ms linear infinite normal forwards}#emRAlf8Hn1x6_ts{animation:emRAlf8Hn1x6_ts__ts 1700ms linear infinite normal forwards}#emRAlf8Hn1x6{animation:emRAlf8Hn1x6_c_o 1700ms linear infinite normal forwards}#emRAlf8Hn1x7_ts{animation:emRAlf8Hn1x7_ts__ts 1700ms linear infinite normal forwards}#emRAlf8Hn1x7{animation:emRAlf8Hn1x7_c_o 1700ms linear infinite normal forwards}\n  '
				}
			</style>
			<rect
				id="emRAlf8Hn1x2"
				width={50}
				height={50}
				fill="#121213"
				stroke="none"
				strokeWidth={0}
				display="none"
				rx={0}
				ry={0}
			/>
			<path
				id="emRAlf8Hn1x3"
				fill="#FFF"
				stroke="none"
				strokeWidth={1}
				d="M11.672 15.5V8.919c0-2.43 1.97-4.4 4.4-4.4 2.43 0 4.4 1.97 4.4 4.4V15.5c2.9-1.938 4.1906-5.5453 3.178-8.8831-1.0127-3.3378-4.09-5.62-7.578-5.62-3.488 0-6.5653 2.2822-7.578 5.62-1.0126 3.3378.278 6.945 3.178 8.8831zm9.679 4.347a3.474 3.474 0 00-1.566-.37h-1.074V8.919c-.028-1.4377-1.2015-2.5886-2.6395-2.5886S13.46 7.4813 13.432 8.919v18.9l-6.054-1.266a1.978 1.978 0 00-1.813 3.326l7.057 7.057a3.589 3.589 0 002.5 1.021h10.734c1.7547.0037 3.2425-1.289 3.484-3.027l1.109-7.866a3.522 3.522 0 00-1.918-3.643z"
				display="none"
				transform="translate(7.2612 5.523)"
			/>
			<g id="emRAlf8Hn1x4" transform="translate(12.2582 6.523)">
				<g id="emRAlf8Hn1x5_ts" transform="translate(12.7418 21.1437)">
					<path
						id="emRAlf8Hn1x5"
						fill="#FFF"
						stroke="none"
						strokeWidth={1}
						d="M21.351 19.848a3.474 3.474 0 00-1.566-.37h-1.074V8.919c-.028-1.4377-1.2015-2.5886-2.6395-2.5886S13.46 7.4813 13.432 8.919v18.9l-6.054-1.266a1.978 1.978 0 00-1.813 3.326l7.057 7.057a3.589 3.589 0 002.5 1.021h10.734c1.7547.0037 3.2425-1.289 3.484-3.027l1.109-7.866a3.522 3.522 0 00-1.918-3.643z"
						transform="translate(-17.7388 -22.1437)"
					/>
				</g>
			</g>
			<g id="emRAlf8Hn1x6_ts" transform="matrix(0 0 0 0 23.48 15.9881)">
				<circle id="emRAlf8Hn1x6" r={7.4457} fill="#FFF" stroke="none" strokeWidth={0} opacity={0.6} />
			</g>
			<g id="emRAlf8Hn1x7_ts" transform="matrix(0 0 0 0 23.48 15.9881)">
				<circle id="emRAlf8Hn1x7" r={7.4457} fill="#FFF" stroke="none" strokeWidth={0} opacity={0.6} />
			</g>
		</svg>
	)
}

export default DoubleTapSVG
