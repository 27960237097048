import React, { useState, useRef, useEffect, memo } from 'react'
import { maxMediaCount, maxMediaLength } from '../../../../../../../client/config.client'
import AddMediaSVG from './AddMediaSVG'
import { GridItem, Button, Flex } from '@chakra-ui/react'
import useSize from '../../../../../hooks/useSize'

const gridItemStyles = {
	backgroundColor: 'white',
	width: 'full',
	h: 'full',
	borderRadius: 'md',
	border: '1px',
	borderStyle: 'dashed',
	borderColor: 'gray.300',
	position: 'relative',
	overflow: 'hidden',
	cursor: 'pointer',
	_hover: {
		cursor: 'pointer !important',
		backgroundColor: 'rgba(0,102,204, 0.1)',
		borderColor: 'rgb(0,102,204)',
	},
}

const addMediaSvgStyles = {
	position: 'absolute',
	top: '0.5rem',
	h: '90%',
	w: 'full',
	align: 'center',
	direction: 'column',
	_hover: { cursor: 'pointer !important' },
}

const AddMediaSvg = memo(() => {
	return (
		<Flex sx={addMediaSvgStyles}>
			<AddMediaSVG />
		</Flex>
	)
})

const addButtonStyles = {
	position: 'absolute',
	transform: 'translateX(-50%)',
	left: '50%',
	color: 'link',
	bottom: '10%',
}

const AddButton = memo(() => {
	return (
		<Button sx={addButtonStyles} size={['xs', 'sm']} variant="text">
			Add Media
		</Button>
	)
})

const AddMedia = ({ itemsLength, videoLengthInSec, modals: { addMediaItem, maxMedia, maxLength } }) => {
	const containerRef = useRef(null)

	const size = useSize(containerRef)
	const [minH, setMinH] = useState(false)
	const [showButton, setShowButton] = useState(true)

	useEffect(() => {
		/** Force matching the height to the width of the element */
		if (size && size.width != size.height) {
			setMinH(size.width)
			setShowButton(size.width > 120 ? true : false)
		}
	}, [size])

	const addMedia = () => {
		if (itemsLength >= maxMediaCount) {
			maxMedia.onOpen()
		} else if (videoLengthInSec >= maxMediaLength) {
			maxLength.onOpen()
		} else {
			addMediaItem.onToggle()
		}
	}

	return (
		<GridItem sx={gridItemStyles} ref={containerRef} minH={minH ? minH : 'auto'} maxW="240px" onClick={addMedia}>
			<AddMediaSvg />
			<AddButton />
		</GridItem>
	)
}

export default AddMedia
