import React, { useState, memo } from 'react'
import { Flex, Box, Text, Heading, Button, VStack, HStack, useDisclosure } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import {
	Thumbnail,
	BottomSection,
	CollageBackground,
	CollageForeground,
	BackgroundMusic,
	Muted,
} from '../SortableContainer/Element/atoms'
import { unArchiveMedia } from '../../../../../api/app/media'
import { computeMimeType } from '../../../../contexts/MediaItemProvider'
import { ArrowForwardRounded, ArrowDownwardRounded } from '../../../atoms/Icon'

const ArchivedTab = memo(({ hasCollageBackground, items }) => {
	const dispatch = useDispatch()
	const tray = useDisclosure()
	const [showArchived, setShowArchived] = useState(false)

	const handleUnArchiveMedia = (id) => {
		dispatch(unArchiveMedia({ id: id }))
	}

	return (
		<Box w="full" p="1rem" borderTop="1px" borderColor="blackAlpha.300">
			<Flex w="full" justify="space-between" alignItems="center" cursor="pointer" onClick={tray.onToggle}>
				<Flex direction="column">
					<Heading as="h4" size="md" pb="0.25rem">
						Archived Media
					</Heading>
					<Text>This media will not be part of the final video.</Text>
				</Flex>
				<Flex>
					<Button aria-label="Archived Media" size="md" variant="iconCta">
						{tray.isOpen ? <ArrowDownwardRounded /> : <ArrowForwardRounded />}
					</Button>
				</Flex>
			</Flex>
			{tray.isOpen && (
				<VStack mt="1rem">
					{items.map((el, i) => {
						const type = computeMimeType(el.mimeType)
						const isVideo = type == 'video'

						/**
						 * Tags Conditions
						 */
						const displayCollageBackground =
							el.backgroundEligible &&
							!el.foregroundEligible &&
							el.signedThumbnailUrl &&
							hasCollageBackground

						const displayCollageForeground =
							el.foregroundEligible &&
							!el.backgroundEligible &&
							el.signedThumbnailUrl &&
							hasCollageBackground

						const displayBackgroundMusic = el.muteBackgroundMusic
						const displayMute = el.muteAudio && isVideo

						return (
							<Flex
								border="1px"
								borderColor="blackAlpha.300"
								w="full"
								minH="100px"
								borderRadius="md"
								overflow="hidden"
								key={i}
								justify="space-between"
								pr="1rem">
								<Box position="relative" h="100px" w="100px" borderRadius="md">
									<Thumbnail item={el} gridSize="small" />
									<BottomSection item={el} hasCollageBackground={hasCollageBackground} />
									<Box position="absolute" top="0" left="0" right="0" h="75px">
										<VStack p="0.5rem" align="start" spacing="0.25rem">
											{displayCollageBackground && <CollageBackground />}
											{displayCollageForeground && <CollageForeground />}
											{displayBackgroundMusic && <BackgroundMusic />}
											{displayMute && <Muted />}
										</VStack>
									</Box>
								</Box>
								<HStack spacing="0.5rem">
									<Button onClick={() => handleUnArchiveMedia(el.id)} size="sm">
										Move to Gallery
									</Button>
									<Button
										onClick={() =>
											alert(
												"This doesn't actually do anything, but if you think is should, talk to your local developer."
											)
										}
										size="sm"
										colorScheme="red">
										Destroy
									</Button>
								</HStack>
							</Flex>
						)
					})}
				</VStack>
			)}
		</Box>
	)
})
// , areEqual)

// const areEqual = (prevProps, nextProps) => {
// 	/**
// 	 * If we have a previous and next event,
// 	 * If id is the same for both event,
// 	 * If the modified date is identical (Warning: because the date will differ on a sort, this will cause re-render)
// 	 */
// 	if (
// 		prevProps.event &&
// 		nextProps.event &&
// 		prevProps.event.id === nextProps.event.id &&
// 		prevProps.event.updatedAt === nextProps.event.updatedAt
// 	) {
// 		return true
// 	} else if (
// 		prevProps.items &&
// 		nextProps.items &&
// 		JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items)
// 	) {
// 		return true
// 	} else return false
// }

export default ArchivedTab
