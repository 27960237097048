import React, { memo } from 'react'
import { maxMediaCount } from '../../../../../client/config.client'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalMaxMedia = memo(({ onClose, isOpen, mediaCount }) => {
	const title = `This upload will exceed the event's limits of ${maxMediaCount} media items.`
	const message = (
		<Text fontSize="1.125rem">
			Please remove other media before adding more. <br /> You're current media items total is {mediaCount}.
		</Text>
	)
	const acceptLabel = 'Okay'

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Warning" />
						<Heading size="lg" variant="hero">
							{title}
						</Heading>
						{message}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							onClick={() => {
								onClose()
							}}>
							{acceptLabel}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalMaxMedia
