import { useDispatch } from 'react-redux'
import { restoreAllMedia } from '../../api/app/media'

const useAutoRestoreGlacierMedia = (event) => {
	const dispatch = useDispatch()
	if (event && event.status === 'active' && event.inGlacierStorage && !event.isRestoring) {
		dispatch(restoreAllMedia({ eventId: event.id }))
	}
}
export default useAutoRestoreGlacierMedia
