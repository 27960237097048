import { useSelector, shallowEqual } from 'react-redux'

/**
 * Retrieve media of the current event (can be improved to retrieve media of any event)
 * @param {*} event
 * @returns
 */
export const useMediaArchived = () => {
	/** Retrieve all medias from the store */
	const media = useSelector((s) => s.event.mediaArchived, shallowEqual)

	return media
}
