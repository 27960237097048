import React, { memo } from 'react'
import { Box, Text, Heading, useDisclosure } from '@chakra-ui/react'
import { useVideoDurationCalc } from '../../../../hooks/useVideoDurationCalc'
import { useMedia } from '../../../../hooks/useMedia'
import ModalGalleryInfo from '../../../organisms/ModalGalleryInfo'
import { AccessTimeRounded } from '../../../atoms/Icon'

const areEqual = (prevProps, nextProps) => {
	/**
	 * If we have a previous and next event,
	 * If id is the same for both event,
	 * If the modified date is identical (Warning: because the date will differ on a sort, this will cause re-render)
	 */
	if (
		prevProps.event &&
		nextProps.event &&
		prevProps.event.id === nextProps.event.id &&
		prevProps.event.updatedAt === nextProps.event.updatedAt
	) {
		return true
	} else return false
}

const MediaGalleryHeader = memo(({ event }) => {
	const { themeTemplateId } = event
	const media = useMedia()
	const { renderTime, durationTime } = useVideoDurationCalc(media, themeTemplateId, true)
	const galleryInfo = useDisclosure()

	return (
		<>
			<ModalGalleryInfo
				onClose={galleryInfo.onClose}
				isOpen={galleryInfo.isOpen}
				renderTime={renderTime}
				durationTime={durationTime}
			/>

			<Box p="1rem">
				<Heading as="h3" size="md" d="flex" flexDirection="row">
					Media{' '}
					{durationTime && (
						<Text
							mx="0.5rem"
							display="flex"
							alignItems="center"
							fontFamily="body"
							fontSize="0.875rem"
							color="link"
							onClick={galleryInfo.onOpen}>
							<AccessTimeRounded mr="0.25rem" width="1rem" height="1rem" /> {durationTime}
						</Text>
					)}
				</Heading>

				<Text fontSize="0.875rem">
					<strong>Click and drag</strong> to arrange the order, and <strong>double-tap</strong> to
					review/edit.
				</Text>
			</Box>
		</>
	)
}, areEqual)

export default MediaGalleryHeader
