import React from 'react'
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalFooter,
	HStack,
	VStack,
	Image,
	Heading,
	Text,
	Button,
} from '@chakra-ui/react'
const ModalGalleryDuplicateItems = (props) => {
	const { onClose, isOpen, fileCount, onDuplicate } = props

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image maxW="100px" src="/assets/images/graphics/modal-duplicate.svg" alt="Media Duplicate" />
						<Heading as="h3" size="lg" variant="hero">
							Duplicate Media?
						</Heading>

						<Text>
							You are about to{' '}
							<strong>
								duplicate {fileCount} item{fileCount > 1 ? 's' : ''}
							</strong>
							.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button onClick={onDuplicate}>Yes, Duplicate</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default ModalGalleryDuplicateItems
